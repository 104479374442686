import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Images for page
import ImagePropis from "../images/englet-1.jpg"
import Ozon from "../images/ozonme.png"
import Wild from "../images/wildberries.png"

// Images for carousel
import russian1 from "../images/englet-2.jpg"
import russian2 from "../images/englet-3.jpg"
import russian3 from "../images/englet-4.jpg"
import russian4 from "../images/englet-5.jpg"
import russian5 from "../images/englet-6.jpg"
import russian6 from "../images/englet-7.jpg"
import russian7 from "../images/englet-8.jpg"
import russian8 from "../images/englet-9.jpg"

const wildRus =
  "https://www.wildberries.ru/catalog/50392107/detail.aspx?targetUrl=BP"
const ozonRus =
  "https://www.ozon.ru/product/propisi-rabochaya-tetrad-russkiy-yazyk-avtor-ne-ukazan-327595175/?_bctx=CAQQjtMN&asb2=JCXaL9AnCQOnl8uVFrO5yvFxNz4G0bomkQnLTaT9VTl1CJBNIdbiQE4K_qwBkXy1&hs=1&sh=f8T_Z4Xx"

const imgRussian = [
  {
    id: 1,
    pic: russian1,
  },
  {
    id: 2,
    pic: russian2,
  },
  {
    id: 3,
    pic: russian3,
  },
  {
    id: 4,
    pic: russian4,
  },
  {
    id: 5,
    pic: russian5,
  },
  {
    id: 6,
    pic: russian6,
  },
  {
    id: 7,
    pic: russian7,
  },
  {
    id: 8,
    pic: russian8,
  },
]

function PropisEnglet() {
  const imageMap = imgRussian.map(image => {
    return (
      <div key={image.id}>
        <div className="flex justify-center">
          <img className="max-h-350" src={image.pic} />
        </div>
      </div>
    )
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Seo title="Прописи английский язык" />
      <div className="mb-4">
        <a href="/">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          >
            На главную
          </button>
        </a>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <img src={ImagePropis} />
        </div>
        <div className="pl-0 md:pl-8 md:w-1/2">
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            Прописи английский язык
          </h1>
          <span className="mt-12">
            <p>
              Пропись по английскому языку подходит для дошкольников и младших
              школьников, начинающих изучать английский язык, для тех, кто
              делает первые шаги в занятиях английским.
            </p>
            <p>
              В рабочей тетради упражнения по письму разбиты на следующие этапы:{" "}
            </p>
            <span>
              - печатные английские буквы (большие и маленькие) и слова на
              разные темы;
            </span>{" "}
            <br />
            <span>- цвета;</span>
            <br />
            <span>- цифры;</span>
            <br />
            <span>- животные;</span>
            <br />
            <span>- овощи и фрукты;</span>
            <br />
            <span>- части тела человека;</span>
            <br />
            <br />
            {/* <span className="mb-6">- алфавиту.</span><br /><br /> */}
            <span>
              Каждая буква или слово - это одно упражнение, состоящее из двух
              пунктирных строчек и одной пустой, предназначенной для
              самостоятельной работы.{" "}
            </span>
            <br />
            <span>
              У английских букв вы найдете транскрипцию на русском языке. У
              каждого английского слова есть перевод. Все слова написаны с
              большой буквы, чтобы отрабатывать на практике и не забыть их
              написание.{" "}
            </span>
            <br />
            <span>
              У цифр перевод в виде чисел, которые тоже можно потренироваться
              писать в пунктирах.
            </span>
            <br />
            <span>
              Сборник упражнений поможет начать осваивать английскую письменную
              речь. Прописывание слов поможет запомнить их правила написания и
              перевод. Приятной учебы!
            </span>
          </span>
          <div className="m-0">
            <div className="flex flex-col md:flex-row justify-around mt-6">
              {/* <div className="flex flex-col items-center">
                <img 
                  className="w-28 mt-4"
                  src={Ozon} 
                  alt="ozon"
                />
                  <a href={ozonRus} target="_blank" rel="noopener noreferrer">
                    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center">
                      Купить на Ozon
                      <svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                  </a>
              </div> */}
              <div className="flex flex-col items-center mt-6 md:mt-0">
                <img className="w-44" src={Wild} alt="ozon" />
                <a href={wildRus} target="_blank" rel="noopener noreferrer">
                  <button
                    type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                  >
                    Купить на Wildberries
                    <svg
                      class="-mr-1 ml-2 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-24 mb-24">
          <Slider {...settings}>{imageMap}</Slider>
        </div>
      </div>
    </Layout>
  )
}

export default PropisEnglet
